import styled from '@emotion/styled'
import { Heading, Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { Place } from './place'
import { usePlaces } from './use-places'

const PlacesList = styled.ul(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  overflowX: 'auto',
  maxWidth: '100vw',
  margin: `0 -${theme.spacing['8x']}`,
  paddingLeft: theme.spacing['8x'],
  paddingRight: theme.spacing['8x'],
  paddingBottom: theme.spacing['4x'],
  gap: theme.spacing['4x'],
}))
const PlaceListItem = styled.li({
  flexShrink: 0,
  flexGrow: 0,
})

export function PopularPlaces() {
  const { t } = useTranslation('landing', { keyPrefix: 'popular_places' })
  const placeDeclarations = usePlaces()
  return (
    <Stack gap="8x">
      <Heading as="h2" id="popular-places">
        {t('title')}
      </Heading>
      <PlacesList>
        {placeDeclarations.map(({ name, identifier, image, searchArea }, index) => {
          return (
            <PlaceListItem key={index}>
              <Place identifier={identifier} name={name} image={image} searchArea={searchArea} />
            </PlaceListItem>
          )
        })}
      </PlacesList>
    </Stack>
  )
}
