import type { TFunction } from 'i18next'

import { BRAND_CONFIG } from '../../config/brand-configurations'

type SEOLink = {
  name: string
  searchArea: { name: string; city?: string | null; countryCode?: string | null }
  sharedHome?: boolean
}

export const getSEOLinks = ({ t }: { t: TFunction }): Array<SEOLink[]> => {
  const region = BRAND_CONFIG.countryCode
  const places: Record<'se' | 'fi' | 'fr', Array<SEOLink[]>> = {
    fi: [
      [
        { name: t('places.helsinki'), searchArea: { name: 'Helsinki', countryCode: 'fi' } },
        { name: t('places.oulu'), searchArea: { name: 'Oulu', countryCode: 'fi' } },
        { name: t('places.tampere'), searchArea: { name: 'Tampere', countryCode: 'fi' } },
        { name: t('places.turku'), searchArea: { name: 'Turku', countryCode: 'fi' } },
        { name: t('places.jyväskylä'), searchArea: { name: 'Jyväskylä', countryCode: 'fi' } },
      ],
      [
        { name: t('places.vantaa'), searchArea: { name: 'Vantaa', countryCode: 'fi' } },
        { name: t('places.joensuu'), searchArea: { name: 'Joensuu', countryCode: 'fi' } },
        { name: t('places.kuopio'), searchArea: { name: 'Kuopio', countryCode: 'fi' } },
        { name: t('places.espoo'), searchArea: { name: 'Espoo', countryCode: 'fi' } },
        { name: t('places.uusimaa'), searchArea: { name: 'Uusimaa', countryCode: 'fi' } },
      ],
      [
        {
          name: t('places.lappeenranta'),
          searchArea: { name: 'Lappeenranta', countryCode: 'fi' },
        },
        { name: t('places.kempele'), searchArea: { name: 'Uusimaa', countryCode: 'fi' } },
        { name: t('places.lahti'), searchArea: { name: 'Lahti', countryCode: 'fi' } },
        { name: t('places.rovaniemi'), searchArea: { name: 'Rovaniemi', countryCode: 'fi' } },
        { name: t('places.kajaani'), searchArea: { name: 'Kajaani', countryCode: 'fi' } },
      ],
      [
        { name: t('places.pori'), searchArea: { name: 'Pori', countryCode: 'fi' } },
        { name: t('places.mikkeli'), searchArea: { name: 'Mikkeli', countryCode: 'fi' } },
        {
          name: t('places.hämeenlinna'),
          searchArea: { name: 'Hämeenlinna', countryCode: 'fi' },
        },
        { name: t('places.porvoo'), searchArea: { name: 'Porvoo', countryCode: 'fi' } },
        { name: t('places.kouvola'), searchArea: { name: 'Kouvola', countryCode: 'fi' } },
      ],
    ],
    se: [
      [
        { name: t('places.borås'), searchArea: { name: 'Borås', countryCode: 'se' } },
        { name: t('places.göteborg'), searchArea: { name: 'Göteborg', countryCode: 'se' } },
        {
          name: t('places.helsingborg'),
          searchArea: { name: 'Helsingborg', countryCode: 'se' },
        },
        { name: t('places.jönköping'), searchArea: { name: 'Jönköping', countryCode: 'se' } },
        { name: t('places.kalmar'), searchArea: { name: 'Kalmar', countryCode: 'se' } },
        { name: t('places.karlstad'), searchArea: { name: 'Karlstad', countryCode: 'se' } },
        {
          name: t('places.kristianstad'),
          searchArea: { name: 'Kristianstad', countryCode: 'se' },
        },
      ],
      [
        { name: t('places.linköping'), searchArea: { name: 'Linköping', countryCode: 'se' } },
        { name: t('places.luleå'), searchArea: { name: 'Luleå', countryCode: 'se' } },
        { name: t('places.lund'), searchArea: { name: 'Lund', countryCode: 'se' } },
        {
          name: t('places.lund'),
          sharedHome: true,
          searchArea: { name: 'Lund', countryCode: 'se' },
        },
        { name: t('places.malmö'), searchArea: { name: 'Malmö', countryCode: 'se' } },
        { name: t('places.norrköping'), searchArea: { name: 'Norrköping', countryCode: 'se' } },
        { name: t('places.örebro'), searchArea: { name: 'Örebro', countryCode: 'se' } },
        { name: t('places.sundsvall'), searchArea: { name: 'Sundsvall', countryCode: 'se' } },
      ],
      [
        {
          name: t('places.stockholm_enskede'),
          searchArea: {
            name: 'Enskede-, Årsta-Vantörs_stadsdelsområde',
            city: 'Stockholm',
            countryCode: 'se',
          },
        },
        {
          name: t('places.stockholm_hägersten_älvsjö'),
          searchArea: {
            name: 'Hägersten-Älvsjö stadsdelsområde',
            city: 'Stockholm',
            countryCode: 'se',
          },
        },
        {
          name: t('places.stockholm_norrmalm'),
          searchArea: { name: 'Norrmalm', city: 'Stockholm', countryCode: 'se' },
        },
        {
          name: t('places.stockholm_östermalm'),
          searchArea: { name: 'Östermalm', city: 'Stockholm', countryCode: 'se' },
        },
        {
          name: t('places.stockholm_södermalm'),
          searchArea: { name: 'Södermalm', city: 'Stockholm', countryCode: 'se' },
        },
        {
          name: t('places.stockholm_solna'),
          searchArea: { name: 'Solna_kommun', countryCode: 'se' },
        },
        {
          name: t('places.stockholm_sundbyberg'),
          searchArea: { name: 'Sundbyberg', countryCode: 'se' },
        },
        {
          name: t('places.stockholm_vasastan'),
          searchArea: { name: 'Vasastaden', city: 'Stockholm', countryCode: 'se' },
        },
      ],
      [
        { name: t('places.stockholm'), searchArea: { name: 'Stockholm', countryCode: 'se' } },
        {
          name: t('places.stockholm'),
          sharedHome: true,
          searchArea: { name: 'Stockholm', countryCode: 'se' },
        },
        {
          name: t('places.stockholm_county'),
          searchArea: { name: 'Stockholms_kommun', countryCode: 'se' },
        },
        { name: t('places.umeå'), searchArea: { name: 'Umeå', countryCode: 'se' } },
        { name: t('places.uppsala'), searchArea: { name: 'Uppsala', countryCode: 'se' } },
        {
          name: t('places.uppsala'),
          sharedHome: true,
          searchArea: { name: 'Uppsala', countryCode: 'se' },
        },
        { name: t('places.västerås'), searchArea: { name: 'Västerås', countryCode: 'se' } },
        { name: t('places.ystad'), searchArea: { name: 'Ystad', countryCode: 'se' } },
      ],
    ],
    fr: [],
  }

  if (region === 'international') {
    return [...places.se, ...places.fi]
  }
  return places[region]
}
