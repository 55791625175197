import styled from '@emotion/styled'
import { Button, Paragraph, Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import husfrid1920 from '../../../assets/images/landing/husfrid-campaign/1920x1080.png'
import husfrid1280 from '../../../assets/images/landing/husfrid-campaign/1280x1175.png'
import husfrid767 from '../../../assets/images/landing/husfrid-campaign/767x1175.png'
import { Link } from '../../../vendor/next'
import { useCurrentLocale } from '../../../translations/use-current-locale'

import { ResponsiveImage } from './responsive-image'

const Image = styled(ResponsiveImage)({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  background: `url(${husfrid767})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  maxHeight: 747,
  minHeight: 578,
})

const Wrapper = styled.div({
  position: 'relative',
})

const OverlayContent = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
  padding: `${theme.spacing['8x']} ${theme.spacing['6x']}`,
  [theme.mediaQueries.mdUp]: {
    padding: `${theme.spacing['24x']} 10%`,
  },
}))

const Heading = styled.h2(({ theme }) => ({
  color: theme.colors.core.white,
  ...theme.typography.display.md,
  [theme.mediaQueries.smUp]: theme.typography.display.lg,
  [theme.mediaQueries.mdUp]: {
    ...theme.typography.display.xl,
    whiteSpace: 'pre-line',
  },
}))

const WhiteParagraph = styled(Paragraph)(({ theme }) => ({
  color: theme.colors.core.white,
}))

export function HusfridCampaign() {
  const { t } = useTranslation('landing', { keyPrefix: 'husfrid' })
  const locale = useCurrentLocale()

  const husfridHref = locale === 'sv' ? 'https://go.qasa.se/husfrid/' : 'https://go.qasa.se/husfrid_eng/'

  return (
    <Wrapper>
      <Image
        src={husfrid767}
        alt=""
        loading="eager"
        fetchPriority="high"
        srcSet={{ 1920: husfrid1920, 1280: husfrid1280, 767: husfrid767 }}
        sizes="100vw"
      />
      <OverlayContent justifyContent="space-between" alignItems="flex-start">
        <WhiteParagraph size="xl">{t('label')}</WhiteParagraph>
        <Heading>{t('heading')}</Heading>
        <Button as={Link} size={'md'} variant={'primary'} href={husfridHref}>
          {t('cta')}
        </Button>
      </OverlayContent>
    </Wrapper>
  )
}
