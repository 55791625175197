import { UspsGrid } from '../components/usps-grid'
import { Hero } from '../components/hero/hero'
import { Questions } from '../components/questions/questions'
import { CTA } from '../components/cta'
import { Stats } from '../components/stats'
import { Testimonial } from '../components/testimonial'
import { ServiceBreakdownSection } from '../components/services-breakdown-section'
import { HowItWorks } from '../components/how-it-works/how-it-works'
import { ProductShowcase } from '../components/product-showcase'
import { Articles } from '../components/articles'
import { Partnerships } from '../components/partnerships'
import { WelcomeHomeCard } from '../components/welcome-home-card'

export default function LandingPageContent() {
  return (
    <>
      <Hero />
      <HowItWorks />
      <ProductShowcase />
      <Partnerships />
      <UspsGrid />
      <Stats />
      <Testimonial />
      <Articles />
      <ServiceBreakdownSection />
      <CTA />
      <WelcomeHomeCard />
      <Questions />
    </>
  )
}
