import styled from '@emotion/styled'
import { Button, Paragraph, Spacer, Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { EXTERNAL_LINKS } from '../../../../config/external-links'
import { currentBrand } from '../../../../config'
import { useCurrentLocale } from '../../../../translations/use-current-locale'
import { FaqItem } from '../../../../ui-shared/faq-item'

import { getQuestions } from './util'

const Wrapper = styled.div(({ theme }) => ({
  padding: `${theme.spacing['8x']} ${theme.spacing['6x']}`,

  [theme.mediaQueries.lgUp]: {
    padding: `${theme.spacing['24x']} 10%`,
  },
}))

const Grid = styled.div(({ theme }) => ({
  display: 'grid',
  [theme.mediaQueries.lgUp]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridAutoRows: 'max-content',
    columnGap: theme.spacing['6x'],
  },
}))

export function Questions() {
  const { t } = useTranslation('landing', { keyPrefix: 'questions' })
  const language = useCurrentLocale()
  const questions = getQuestions({ language })
  const translationOptions = { context: currentBrand }

  if (!questions.length) {
    return null
  }

  return (
    <Wrapper>
      <Paragraph size={{ base: 'sm', lg: 'lg' }}>{t('title', translationOptions)}</Paragraph>
      <Spacer size={{ base: '12x', lg: '16x' }} />

      <Grid>
        {questions.map(({ nameI18nKey, href }) => {
          return <FaqItem key={nameI18nKey} href={href} name={t(`links.${nameI18nKey}`)} />
        })}
      </Grid>
      <Spacer size={{ base: '16x', lg: '20x' }} />
      <Stack alignItems="flex-end">
        <Button
          as="a"
          href={EXTERNAL_LINKS.helpCenterUrl}
          variant="tertiary"
          size="xl"
          rel="noopener noreferrer"
          target="_blank"
        >
          {t('cta', translationOptions)}
        </Button>
      </Stack>
    </Wrapper>
  )
}
