import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Heading, Paragraph, Stack } from '@qasa/qds-ui'
import type { StaticImageData } from 'next/image'

import { Link } from '../../vendor/next'
import { currentBackendPlatform } from '../../config'
import { Image } from '../../ui-shared/_core/image'
import { getFindHomeQueryString } from '../find-home/filters/utils/query-string'

import { CITY_HOME_COUNT } from './place.gql'

const CityImage = styled(Image)(({ theme }) => ({
  width: 192,
  height: 128,
  borderRadius: theme.radii.md,
}))

const InfoStack = Stack

type PlaceProps = {
  name: string
  identifier: string
  image: StaticImageData
  searchArea: {
    name: string
    countryCode: string
  }
}

export function Place({ name, searchArea, identifier, image }: PlaceProps) {
  const { t } = useTranslation(['landing', 'image_alt_tags'])
  const { data: homeCountData } = useQuery(CITY_HOME_COUNT, {
    variables: {
      searchParams: {
        platform: currentBackendPlatform,
        areaIdentifier: [identifier],
      },
    },
  })

  const queryStrings = getFindHomeQueryString({
    filterValues: { searchAreas: [searchArea] },
  })

  return (
    <Link href={`/find-home${queryStrings}`}>
      <Stack gap="3x">
        <CityImage alt={t('image_alt_tags:images.cities', { cityName: name })} src={image.src} />
        <InfoStack>
          <Heading as="span" size="sm" numberOfLines={1}>
            {name}
          </Heading>
          <Paragraph as="span" size="sm" numberOfLines={1}>
            {t('popular_places.home', { count: homeCountData?.homeSearch.documents.totalCount })}
          </Paragraph>
        </InfoStack>
      </Stack>
    </Link>
  )
}
