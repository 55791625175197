import { useTranslation } from 'react-i18next'
import zip from 'lodash/zip'

import { gothenburg, malmo, linkoping, stockholm, uppsala } from '../../assets/images/places'
import { helsinki, jyvaskyla, kuopio, lahti, oulu, pori, tampere, turku } from '../../assets/images/places_fi'
import { currentBrand } from '../../config'
import { notEmptyFilter } from '../../utils/array'

export function usePlaces() {
  const { t } = useTranslation('landing', { keyPrefix: 'list_of_seo_links' })

  const sePlaces = [
    {
      name: 'Stockholm',
      identifier: 'se/stockholms_kommun',
      searchArea: { name: 'Stockholm', countryCode: 'se' },
      image: stockholm,
    },
    {
      name: t('places.gothenburg'),
      identifier: 'se/göteborg',
      searchArea: { name: 'Göteborg', countryCode: 'se' },
      image: gothenburg,
    },
    {
      name: 'Malmö',
      identifier: 'se/malmö',
      searchArea: { name: 'Malmö', countryCode: 'se' },
      image: malmo,
    },
    {
      name: 'Uppsala',
      identifier: 'se/uppsala_kommun',
      searchArea: { name: 'Uppsala', countryCode: 'se' },
      image: uppsala,
    },
    {
      name: 'Linköping',
      identifier: 'se/linköping',
      searchArea: { name: 'Linköping', countryCode: 'se' },
      image: linkoping,
    },
  ]

  const fiPlaces = [
    {
      name: 'Kuopio',
      identifier: 'fi/kuopio',
      searchArea: { name: 'Kuopio', countryCode: 'fi' },
      image: kuopio,
    },
    {
      name: 'Helsinki',
      identifier: 'fi/helsinki',
      searchArea: { name: 'Helsinki', countryCode: 'fi' },
      image: helsinki,
    },
    {
      name: 'Tampere',
      identifier: 'fi/tampere',
      searchArea: { name: 'Tampere', countryCode: 'fi' },
      image: tampere,
    },
    {
      name: 'Lahti',
      identifier: 'fi/lahti',
      searchArea: { name: 'Lahti', countryCode: 'fi' },
      image: lahti,
    },
    {
      name: 'Jyväskylä',
      identifier: 'fi/jyvaskyla',
      searchArea: { name: 'Jyvaskyla', countryCode: 'fi' },
      image: jyvaskyla,
    },
    {
      name: 'Oulu',
      identifier: 'fi/oulu',
      searchArea: { name: 'Oulu', countryCode: 'fi' },
      image: oulu,
    },
    {
      name: 'Pori',
      identifier: 'fi/pori',
      searchArea: { name: 'Pori', countryCode: 'fi' },
      image: pori,
    },
    {
      name: 'Turku',
      identifier: 'fi/turku',
      searchArea: { name: 'Turku', countryCode: 'fi' },
      image: turku,
    },
  ]
  if (currentBrand === 'dotcom') {
    const mergedPlaces = zip(sePlaces, fiPlaces).flat()
    return mergedPlaces.filter(notEmptyFilter)
  }
  if (currentBrand === 'qasa_finland') {
    return fiPlaces
  }
  return sePlaces
}
