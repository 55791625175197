import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Paragraph } from '@qasa/qds-ui'
import { formatNumber } from '@qasa/app/src/utils/number'

const COUNTER_HEIGHT = 28

const CounterWrapperPlaceholder = styled.div({
  height: COUNTER_HEIGHT,
})
const CounterWrapper = styled.div(({ theme }) => ({
  borderRadius: 999,
  height: COUNTER_HEIGHT,
  paddingLeft: 16,
  paddingRight: 16,
  display: 'inline-flex',
  alignItems: 'center',
  // TODO: this is a temporary one-off background color based on the brand color with 5% opacity
  backgroundColor: `${theme.colors.core.uiPink}0E`,
}))
const CounterText = styled(Paragraph)(({ theme }) => ({
  color: theme.colors.core.uiPink,
  textTransform: 'uppercase',
}))
export function NumberOfHomesCounter({ homeCount }: { homeCount?: number }) {
  const { t } = useTranslation('landing', { keyPrefix: 'top_section' })
  if (!homeCount) {
    return <CounterWrapperPlaceholder />
  }
  return (
    <CounterWrapper>
      <CounterText size="xs">
        {t('number_of_homes_counter', {
          homeCount: formatNumber({
            amount: homeCount,
          }),
        })}
      </CounterText>
    </CounterWrapper>
  )
}
