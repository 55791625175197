import styled from '@emotion/styled'
import { useState } from 'react'
import { Stack, ArrowRightIcon, Heading, Link, useBreakpointValue } from '@qasa/qds-ui'

const QuestionContainer = styled(Stack)(({ theme }) => ({
  borderBottom: '1px solid',
  borderColor: theme.colors.border.default,
  height: '100%',
  padding: '32px 0px 24px 0px',

  [theme.mediaQueries.mdUp]: {
    paddingRight: 42,

    ' &:hover .icon': {
      transition: 'transform 0.3s ease-in-out',
      transform: 'translateX(10px)',
    },
    ' &:not(:hover) .icon': {
      transition: 'transform 0.3s ease-in-out',
      transform: 'translateX(0) ',
    },
  },
}))

const StyledHeading = styled(Heading)(({ theme }) => ({
  ...theme.typography.title.sm,
  fontWeight: 400,

  [theme.mediaQueries.mdUp]: {
    ...theme.typography.title.lg,
    fontWeight: 400,
  },
}))

const StyledLink = styled(Link)<{ isHovering: boolean }>(({ isHovering }) => ({
  textDecorationLine: isHovering ? 'underline' : 'none',
}))

type FaqItemProps = {
  name: string
  href?: string
}
export function FaqItem({ name, href }: FaqItemProps) {
  const [isHovering, setIsHovering] = useState<boolean>(false)
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: true })
  return (
    <StyledLink
      isHovering={isHovering}
      href={href}
      isExternal
      onMouseOver={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <QuestionContainer direction="row" justifyContent="space-between" alignItems="center">
        <StyledHeading size="lg">{name}</StyledHeading>
        <ArrowRightIcon size={isMobile ? 20 : 32} className="icon" />
      </QuestionContainer>
    </StyledLink>
  )
}
