import styled from '@emotion/styled'
import { Button, Paragraph, Spacer, theme, useBreakpointValue } from '@qasa/qds-ui'
import type { StaticImageData } from 'next/image'
import { useTranslation } from 'react-i18next'

import { ResponsiveImage } from './responsive-image'

const FlexGrid = styled.div<{ isReversed: boolean }>(({ theme, isReversed }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.mediaQueries.mdUp]: {
    flexDirection: isReversed ? 'row-reverse' : 'row',
  },
}))
const FlexItem = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
})
const InnerWrapper = styled.div(({ theme }) => ({
  padding: `${theme.spacing['8x']} ${theme.spacing['6x']}`,
  [theme.mediaQueries.mdUp]: {
    maxWidth: 480,
  },
}))
const Title = styled.h2(({ theme }) => ({
  textWrap: 'pretty',
  ...theme.typography.display.xs,
  [theme.mediaQueries.mdUp]: {
    ...theme.typography.display.md,
  },
}))
const Description = styled(Paragraph)({ whiteSpace: 'pre-line', textWrap: 'pretty' })
const Image = styled(ResponsiveImage)({
  aspectRatio: '1 / 1',
  objectFit: 'cover',
  height: '100%',
  width: '100%',
})

type UspProps = {
  title: string
  description: string
  href?: string
  srcSet: Record<string, StaticImageData>
  isReversed: boolean
}
export function Usp({ title, description, href, srcSet, isReversed }: UspProps) {
  const { t } = useTranslation('usp')
  const isMdOrAbove = useBreakpointValue({ base: false, md: true }, { ssr: true })

  return (
    <FlexGrid isReversed={isReversed}>
      <FlexItem>
        {/* Decorative image, therefore no alt is needed */}
        <Image
          src={srcSet[640]}
          alt=""
          srcSet={srcSet}
          sizes={`100vw, (min-width: ${theme.breakpoints.md}px) 50vw`}
        />
      </FlexItem>
      <FlexItem style={{ alignItems: isMdOrAbove ? 'center' : 'flex-start', justifyContent: 'center' }}>
        <InnerWrapper>
          <Title>{title}</Title>
          <Spacer size={'8x'} />
          <Description size={{ base: 'lg', md: 'xl' }}>{description}</Description>
          <Spacer size={'12x'} />
          {href && (
            <Button as="a" href={href} target="_blank" variant="tertiary">
              {t('cta')}
            </Button>
          )}
        </InnerWrapper>
      </FlexItem>
    </FlexGrid>
  )
}
