import { Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { EXTERNAL_LINKS } from '../../../config/external-links'
import { currentBrand } from '../../../config'
import manOnTrainImg640 from '../../../assets/images/landing/man-on-train/640x640.webp'
import manOnTrainImg768 from '../../../assets/images/landing/man-on-train/768x768.webp'
import manOnTrainImg1024 from '../../../assets/images/landing/man-on-train/1024x1024.webp'
import manOnTrainImg1280 from '../../../assets/images/landing/man-on-train/1280x1280.webp'
import manOnTrainImg1920 from '../../../assets/images/landing/man-on-train/1920x1920.webp'
import manOnTrainImg2560 from '../../../assets/images/landing/man-on-train/2560x2560.webp'
import womanWithChildImg640 from '../../../assets/images/landing/woman-with-child/640x640.webp'
import womanWithChildImg768 from '../../../assets/images/landing/woman-with-child/768x768.webp'
import womanWithChildImg1024 from '../../../assets/images/landing/woman-with-child/1024x1024.webp'
import womanWithChildImg1280 from '../../../assets/images/landing/woman-with-child/1280x1280.webp'
import womanWithChildImg1920 from '../../../assets/images/landing/woman-with-child/1920x1920.webp'
import womanWithChildImg2560 from '../../../assets/images/landing/woman-with-child/2560x2560.webp'
import womanLookingImg640 from '../../../assets/images/landing/woman-looking/640x640.webp'
import womanLookingImg768 from '../../../assets/images/landing/woman-looking/768x768.webp'
import womanLookingImg1024 from '../../../assets/images/landing/woman-looking/1024x1024.webp'
import womanLookingImg1280 from '../../../assets/images/landing/woman-looking/1280x1280.webp'
import womanLookingImg1920 from '../../../assets/images/landing/woman-looking/1920x1920.webp'
import womanLookingImg2560 from '../../../assets/images/landing/woman-looking/2560x2560.webp'

import { Usp } from './usp'

export function UspsGrid() {
  const { t } = useTranslation('usp')
  const translationOptions = { context: currentBrand }
  const isEven = (num: number) => {
    return num % 2 === 0
  }

  const usps = [
    {
      title: t('usp_1.heading', translationOptions),
      description: t('usp_1.description', translationOptions),
      href: EXTERNAL_LINKS['howDoesItWork'],
      srcSet: {
        '640': manOnTrainImg640,
        '768': manOnTrainImg768,
        '1024': manOnTrainImg1024,
        '1280': manOnTrainImg1280,
        '1920': manOnTrainImg1920,
        '2560': manOnTrainImg2560,
      },
    },
    {
      title: t('usp_2.heading', translationOptions),
      description: t('usp_2.description', translationOptions),
      href: EXTERNAL_LINKS['services'],
      srcSet: {
        '640': womanWithChildImg640,
        '768': womanWithChildImg768,
        '1024': womanWithChildImg1024,
        '1280': womanWithChildImg1280,
        '1920': womanWithChildImg1920,
        '2560': womanWithChildImg2560,
      },
    },
    {
      title: t('usp_3.heading', translationOptions),
      description: t('usp_3.description', translationOptions),
      href: EXTERNAL_LINKS['insuranceGuarantee'],
      srcSet: {
        '640': womanLookingImg640,
        '768': womanLookingImg768,
        '1024': womanLookingImg1024,
        '1280': womanLookingImg1280,
        '1920': womanLookingImg1920,
        '2560': womanLookingImg2560,
      },
    },
  ]

  return (
    <Stack direction={'column'}>
      {usps.map(({ title, description, href, srcSet }, index) => (
        <Usp
          key={index}
          title={title}
          description={description}
          href={href}
          srcSet={srcSet}
          isReversed={isEven(index)}
        />
      ))}
    </Stack>
  )
}
